/* Resetting some default browser styles for consistency */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
}

body {
  background-color: #161516;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center; /* To center its children horizontally */
  justify-content: center; /* To center its children vertically */
  height: 100vh;
  /* background-color: black; */
}

.underneath {
    margin-top: 10px;
    font-size: 28px;
    color: #EECF6D;
}

.full-screen-joan {
  font-family: 'Bebas Neue', sans-serif;
  color: #E03616;
  line-height: 1;
  text-align: center;
}

@media (max-aspect-ratio: 1/1) { 
  /* In case the height is greater than the width (e.g., on mobile devices in portrait mode) */
  .full-screen-joan {
    font-size: 20vh;
  }
}

